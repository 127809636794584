import React from "react";
import {CodeBlend} from "../components/code";

const options = [
    {
        name: 'SYBINTL.LIBS',
        scope: 'api',
        description: `Linux/Unix only. Forces SQLAPI++ Library to use specified Sybase INTL library.`,
        valid:
            <>
                Any valid library name list. Names separated by ':'.
            </>,
        defaultValue: <code>"libsybintl.so:libintl.so"</code>
    },
    {
        name: 'SYBCOMN.LIBS',
        scope: 'api',
        description: `Linux/Unix only. Forces SQLAPI++ Library to use specified Sybase COMN library.`,
        valid:
            <>
                Any valid library name list. Names separated by ':'.
            </>,
        defaultValue:
            <code>
                "libsybcomn.so:libcomn.so"
            </code>
    },
    {
        name: 'SYBTCL.LIBS',
        scope: 'api',
        description: `Linux/Unix only. Forces SQLAPI++ Library to use specified Sybase TCL library.`,
        valid:
            <>
                Any valid library name list. Names separated by ':'.
            </>,
        defaultValue:
            <code>
                "libsybtcl.so:libtcl.so"
            </code>
    },
    {
        name: 'SYBCT.LIBS',
        scope: 'api',
        description: `Forces SQLAPI++ Library to use specified Sybase CT-library.`,
        valid:
            <>
                Any valid CT-Lib library name list. Names separated by ';' on
                Windows or ':' on other operating systems.
            </>,
        defaultValue:
            <ul>
                <li>Windows - {' '}<code>"libsybct.dll;libct.dll"</code></li>
                <li>Linux - {' '}<code>"libsybct.so:libct.so"</code></li>
            </ul>,
        special:
            <>
                <code>"STATIC"</code>, forces using the linked Sybase client API functions when
                the library is compiled with {' '}<CodeBlend>SA_STATIC_SYBASE</CodeBlend>{' '} build option.
            </>
    },
    {
        name: 'SYBCS.LIBS',
        scope: 'api',
        description: `Forces SQLAPI++ Library to use specified Sybase CT-library.`,
        valid:
            <>
                Any valid CS-Lib library name list. Names separated by ';' on
                Windows or ':' on other operating systems.
            </>,
        defaultValue:
            <ul>
                <li>Windows - {' '}<code>"libsybcs.dll;libcs.dll"</code></li>
                <li>Linux - {' '}<code>"libsybcs.so:libcs.so"</code></li>
            </ul>
    },
    {
        name: 'CS_VERSION',
        scope: 'api',
        description: `Describes the version of Client-Library behavior that the application expects.
                      For more information see Sybase documentation.`,
        valid:
            <ul>
                <li><code>"CS_VERSION_155"</code>{' '} &ndash; 15.5 behavior</li>
                <li><code>"CS_VERSION_150"</code>{' '} &ndash; 15.0 behavior</li>
                <li><code>"CS_VERSION_125"</code>{' '} &ndash; 12.5 behavior</li>
                <li><code>"CS_VERSION_110"</code>{' '} &ndash; 11.0 behavior</li>
                <li><code>"CS_VERSION_100"</code>{' '} &ndash; 10.0 behavior</li>
                <li>
                    <code>"Detect"</code>{' '} &ndash; SQLAPI++ automatically detects the
                    maximum available version of behavior via the version of Client-Library
                </li>
                <li>
                    <code>"Default"</code>{' '} &ndash; SQLAPI++ tries to set 15.5 behavior; if it fails, tries to set
                    15.0 behavior; if it fails, SQLAPI++ tries to set 12.5 behavior; if it fails, SQLAPI++ tries
                    to set 11.0 behavior and so on from the highest version to the lowest one until it succeeds
                </li>
            </ul>,
        defaultValue:
            <code>"Default"</code>
    },
    {
        name: 'CS_LC_ALL, CS_LOCALE',
        scope: 'api',
        description: `Allows to change the client side connection locale. Should be supported by the
                      server side. For more information see Sybase documentation.`,
        valid:
            <>
                Any valid Sybase locale name
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'CS_SYB_CHARSET',
        scope: 'api',
        description: `Allows to change the client side character set (applies only for non-Unicode
                      Library build). Must be supported by the server side. For more
                      information see Sybase documentation.`,
        valid:
            <>
                Any valid Sybase character set name
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'CS_PACKETSIZE',
        scope: 'connection',
        description: `Determines the packet size that Client-Library uses when sending Tabular
                      Data Stream (TDS) packets. For more information see Sybase documentation.`,
        valid:
            <>
                String containing packet size, see Sybase documentation
            </>,
        defaultValue:
            <>
                See Sybase documentation
            </>
    },
    {
        name: 'CS_APPNAME, APPNAME',
        scope: 'connection',
        description: `Defines the application name that a connection will use when connecting
                      to a server. For more information see Sybase documentation.`,
        valid:
            <>
                See Sybase documentation
            </>,
        defaultValue:
            <>
                See Sybase documentation
            </>
    },
    {
        name: 'CS_HOSTNAME, WSID',
        scope: 'connection',
        description: `Declares the name of the host machine, used when logging in to a server.
                      For more information see Sybase documentation.`,
        valid:
            <>
                See Sybase documentation
            </>,
        defaultValue:
            <>
                See Sybase documentation
            </>
    },
    {
        name: 'CS_BULK_LOGIN',
        scope: 'connection',
        description: `Describes whether or not a connection can perform bulk copy operations into
                      a database. For information on Bulk Copy, see the Sybase Common Libraries Reference Manual.`,
        valid:
            <>
                <code>"CS_TRUE"</code>, {' '}<code>"CS_FALSE"</code>
            </>,
        defaultValue:
            <>
                See Sybase documentation
            </>
    },
    {
        name: 'CS_SEC_ENCRYPTION, CS_SEC_EXTENDED_ENCRYPTION, CS_SEC_NON_ENCRYPTION_RETRY',
        scope: 'connection',
        description: `Describe the connection password encryption details. For information on
                      connection password encryption, see the Sybase Common Libraries Reference Manual.`,
        valid:
            <>
                <code>"CS_TRUE"</code>, {' '}<code>"CS_FALSE"</code>
            </>,
        defaultValue:
            <>
                See Sybase documentation
            </>
    },
    {
        name: 'CS_HAFAILOVER',
        scope: 'connection',
        description: `Describes whether or not a connection uses HA failover. For information
                      on CS_HAFAILOVER, see the Sybase Common Libraries Reference Manual.`,
        valid:
            <>
                <code>"CS_TRUE"</code>, {' '}<code>"CS_FALSE"</code>
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'CS_LOGIN_TIMEOUT',
        scope: 'connection',
        description: `For more information see Sybase documentation.`,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'CS_TIMEOUT',
        scope: 'connection',
        description: `For more information see Sybase documentation.`,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'CS_SEC_SERVERPRINCIPAL',
        scope: 'connection',
        description: `For more information see Sybase documentation.`,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'PreFetchRows',
        scope: 'command',
        description: `Forces SQLAPI++ library to fetch rows in bulk, rather than retrieving records one by one.`,
        valid: 'String containing number of rows in the fetch buffer',
        defaultValue: <code>"1"</code>
    },
    {
        name: 'UseDynamicCursor, Scrollable',
        scope: 'command',
        description: `Forces SQLAPI++ to declare and open Sybase insensitive scrollable cursor.`,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue:
            <code>"False"</code>
    },
    {
        name: 'ct_cursor',
        scope: 'command',
        description:
            <>
                Instructs SQLAPI++ to use {' '}<CodeBlend>ct_cursor</CodeBlend>{' '} for current statement execution
                (as opposed to defaulting to {' '}<CodeBlend>ct_command</CodeBlend>). See Sybase documentation
                for more information on using cursors and their advantages and limitations.
            </>,
        valid:
            <>
                A cursor name. See Sybase documentation on what constitutes a valid cursor name.
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'SybaseResultType',
        scope: 'command',
        description: `Read only. Stores the current result type.`,
        valid:
            <>
                <code>""</code>, {' '}<code>"CS_ROW_RESULT"</code>, {' '}<code>"CS_STATUS_RESULT"</code>,
                {' '}<code>"CS_PARAM_RESULT"</code>, <code>"CS_COMPUTE_RESULT"</code>,
                {' '}<code>"CS_CURSOR_RESULT"</code>
            </>
    },
    {
        name: 'SybaseResultCount',
        scope: 'command',
        description: `Read only. Counts the output results, zero based.`,
        valid:
            <>
                <code>"0"</code>, {' '}<code>"1"</code>, ...
            </>
    }
];

export {
    options
};
