import React from "react";

import ServerGuide, {
    GeneralCancelCommandNotes,
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles,
    GenericPosNotSupportedErrorNotes,
    AutomaticReturnParam
} from "../../components/server-guide";
import {options} from "../../data/ase";
import {Callout} from "../../components/alert";
import {ApiLink} from "../../components/link";

const ConnectApiNotes = () => (
    <Callout heading="Minimum Version">
        The SQLAPI++ Library requires Open Client version 10.0 or higher.
    </Callout>
);
const sDBString = () => (
    <>
        <p>Connection string in the following format:</p>
        <ul>
            <li>
                <code>""</code>{' '} or {' '}<code>"@"</code>{' '} - empty string or '@' character, connects to
                a default database on a local server
            </li>
            <li>
                <code>{`<database_name>`}</code>{' '} or {' '}<code>{`@<database_name>`}</code>{' '} - connects to a
                database with the specified name on local server
            </li>
            <li>
                <code>{`<serve_name>@`}</code>{' '} - connects to a default database on the specified server
            </li>
            <li>
                <code>{`<server_name>@<database_name>`}</code>{' '} - connects to a database with the specified name
                on the specified server
            </li>
        </ul>
    </>
);

const UserID = () => (<p>A string containing a user name to use when establishing the connection.</p>);

const OutputParameters = () => (
    <>
        <p>
            Sybase ASE server does not provide information about parameter's direction type, that's why SQLAPI++ Library
            defines all parameters (except status result code) as input (<code>SA_ParamInput</code>). If you have
            input-output parameters in the procedure you have to
            call {' '}<ApiLink>SAParam::setParamDirType</ApiLink>{' '} method for these
            parameters before command execution and set parameter's direction type explicitly.
        </p>
        <p>
            You shouldn't call {' '}<ApiLink>SAParam::setParamDirType</ApiLink>{' '} method for procedure status result
            code because it is detected correctly (as {' '}<code>SA_ParamReturn</code>) by the Library.
        </p>
        <p>
            There is no need to call {' '}<ApiLink>SAParam::setParamDirType</ApiLink>{' '} method for Sybase ASA because
            all parameters are detected correctly automatically (including the direction type).
        </p>
        <p>
            In Sybase server stored procedures can have integer return codes and output parameters. The return codes and
            output parameters are sent in the last packet from the server and are therefore not available to the
            application until all result sets from stored procedure (if any) are completely processed using
            {' '}<ApiLink>SACommand::FetchNext</ApiLink>{' '} method.
        </p>
        <p>
            <AutomaticReturnParam/>
        </p>
    </>
);

const Page = () => (
    <ServerGuide server="Sybase"
                 connectApiNotes={ConnectApiNotes}
                 sDBString={sDBString}
                 sUserID={UserID}
                 outputParamNotes={OutputParameters}
                 cancelCommandNotes={() => <GeneralCancelCommandNotes server="Sybase"
                                                                      nativeCommand="ct_cancel"/>}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="Sybase"
                                                                        cppHeader="sybAPI.h"
                                                                        apiClass="sybAPI"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="Sybase"
                                                                                handleClass="sybConnectionHandles"
                                                                                cppHeader="sybAPI.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="Sybase"
                                                                          handleClass="sybCommandHandles"
                                                                          cppHeader="sybAPI.h"/>}
                 errorNotes={() => <GenericPosNotSupportedErrorNotes server="Sybase"/>}
    />
);

export default Page;
